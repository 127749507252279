import React, { useState } from 'react';

import { Image, Button, Tiles, Tile, Text, Layout } from '@/components';
import themes from '@/themes';
import { useChangePageState } from '@/state';

import pageHeader from '@/assets/images/page-header.png';
import kraftHeinzLogo from '@/assets/images/kraft-heinz-logo-white.png';
import sausagePack from '@/assets/images/sausage-pack.png';
import cheesePack from '@/assets/images/cheese-pack.png';
import spinach from '@/assets/images/spinach.png';
import zesty from '@/assets/images/zesty.png';
import jello from '@/assets/images/jello.png';
import coolWhip from '@/assets/images/cool-whip.png';
import footerImage from '@/assets/images/dinner-detective-page-footer.png';
import mrSausageHappy from '@/assets/images/mr-sausage-happy.png';
import findIngredientsHeader from '@/assets/images/find-ingredients-header.png';

const tilesList = [
  { title: 'Kraft Macaroni and Cheese', image: cheesePack },
  { title: 'Oscar Mayer Hot dogs', image: sausagePack },
  { title: 'Spinach', image: spinach },
  { title: 'Good Seasons Zesty Italian', image: zesty },
  { title: 'Orange Jello', image: jello },
  { title: 'Cool Whip', image: coolWhip },
];

const FindIngredients = () => {
  const [selected, setSelected] = useState<number[]>([]);
  const changePage = useChangePageState('registration');

  const onSelect = (id: number) => () =>
    setSelected((prevList: number[]) => {
      if (prevList.includes(id)) {
        return prevList.filter((i) => i !== id);
      }

      return [...prevList, id];
    });

  return (
    <Layout.PageWrapper
      styles={{
        padding: '0 0 8rem',
        background: `url("${mrSausageHappy}") left 1rem bottom 6rem no-repeat, url("${footerImage}") left bottom no-repeat`,
        backgroundSize: '20%, contain',
      }}
    >
      <div
        style={{
          display: 'flex',
          padding: '0 1rem 2rem',
          flexDirection: 'column',
          alignItems: 'center',
          background: `#ffffff url("${pageHeader}") left top no-repeat`,
          backgroundSize: '100vw 30vh',
        }}
      >
        <div style={{ margin: '4rem 0 1rem' }}>
          <Image src={kraftHeinzLogo} width="60%" />
        </div>
        <Text theme={themes.kraftHeinz.text}>
          Looks like someone needs help from the Dinner Detective. Can you help
          find these ingredients?
        </Text>
      </div>
      <div style={{ marginBottom: '2rem' }}>
        <Image src={findIngredientsHeader} width="100%" />
      </div>
      <Tiles>
        {tilesList.map((tile, i) => (
          <Tile
            key={i}
            {...tile}
            selectable={true}
            selected={selected.includes(i)}
            onClick={onSelect(i)}
          />
        ))}
      </Tiles>
      <div
        style={{
          margin: '3rem 1.5rem 1rem',
          alignSelf: 'flex-end',
        }}
      >
        <Button
          theme={themes.kraftHeinz.yellowButtonIngredientsSelection}
          disabled={selected.length === 0}
          onClick={changePage}
        >
          Got'em All, Let's go!
        </Button>
      </div>
    </Layout.PageWrapper>
  );
};

export default FindIngredients;
