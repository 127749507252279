import styled from 'styled-components';

export const TilesWrapper = styled.div`
  display: grid;
  width: 100%;
  padding: 1rem;
  grid-template-columns: repeat(2, 48%);
  column-gap: 15px;
  row-gap: 20px;
`;
