import React from 'react';

import { TileWrapper, TileImage, TileTitle } from './styled';

type TileProps = {
  title: string;
  image: string;
  disabled?: boolean;
  selectable?: boolean;
  selected?: boolean;
  tileImageStyles?: {
    [props: string]: any;
  };
  tileTitleStyles?: {
    [props: string]: any;
  };
  onClick?: () => void;
};

const Tile = ({
  title,
  image,
  disabled,
  selected = false,
  selectable = false,
  tileImageStyles = {},
  tileTitleStyles = {},
  onClick,
}: TileProps) => (
  <TileWrapper
    selectable={selectable}
    selected={selected}
    disabled={!!disabled}
    onClick={onClick}
  >
    <TileImage image={image} styles={tileImageStyles} />
    <TileTitle styles={tileTitleStyles}>{title}</TileTitle>
  </TileWrapper>
);

export default Tile;
