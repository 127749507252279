import React from 'react';

import { Image, Button, Text, Layout } from '@/components';
import themes from '@/themes';
import { useChangePageState } from '@/state';

import kraftHeinzLogo from '@/assets/images/kraft-heinz-logo-white.png';
import mrSausage from '@/assets/images/mr-sausage-win.png';
import pageBg from '@/assets/images/win-page-bg.png';
import spinWin from '@/assets/images/spin-win.png';

const Win = () => {
  const changePage = useChangePageState('ingredients');

  return (
    <Layout.PageWrapper
      styles={{
        background: `url("${mrSausage}") left 0.5rem bottom 2rem no-repeat, url("${pageBg}") top center no-repeat`,
        backgroundSize: '20%, cover',
      }}
    >
      <Layout.ContentWrapper>
        <div style={{ margin: '4rem 0 1rem' }}>
          <Image src={kraftHeinzLogo} width="55%" />
        </div>
        <Text fontFamily="Montserrat" weight="600" size="0.9rem">
          Tonight’s 15 Minute Menu is:
        </Text>
        <div style={{ marginTop: '0.5rem' }}>
          <Text fontFamily="Montserrat" weight="600" size="1rem">
            Hot Dog from the Sea With “Seaweed” Salad and “Clownfish” Jello.
          </Text>
        </div>
        <div style={{ margin: '1rem 0 1rem' }}>
          <Image src={spinWin} width="70%" />
        </div>
      </Layout.ContentWrapper>
      <div
        style={{
          margin: '1rem 0.5rem 4rem',
          alignSelf: 'flex-end',
        }}
      >
        <Button
          theme={themes.kraftHeinz.yellowButtonMealSelection}
          onClick={changePage}
        >
          Check out the Menu
        </Button>
      </div>
    </Layout.PageWrapper>
  );
};

export default Win;
