import React from 'react';

import { Image, Tiles, Tile, Text, Layout } from '@/components';
import themes from '@/themes';
import { useChangePageState } from '@/state';

import pageBg from '@/assets/images/meal-style-page-bg.png';
import kraftHeinzLogo from '@/assets/images/kraft-heinz-logo-white.png';
import timeMealIcon from '@/assets/images/15-minute-meal-icon.png';
import celebrationIcon from '@/assets/images/celebration-icon.png';
import healthyIcon from '@/assets/images/healthy-icon.png';
import mysteryIcon from '@/assets/images/mystery-icon.png';
import mrSausage from '@/assets/images/basic-mr-sausage.png';

const tilesList = [
  { title: '15 minute meal', image: timeMealIcon },
  { title: 'Celebration', image: celebrationIcon },
  { title: 'Healthy', image: healthyIcon },
  { title: 'Mystery', image: mysteryIcon },
];

const MealStyle = () => {
  const changePage = useChangePageState('spin-the-wheel');

  return (
    <Layout.PageWrapper
      styles={{
        padding: 0,
        background: `transparent url("${pageBg}") top center no-repeat`,
        backgroundSize: 'cover',
      }}
    >
      <div style={{ margin: '5rem 0 1rem' }}>
        <Image src={kraftHeinzLogo} width="60%" />
      </div>
      <div style={{ marginBottom: '2rem' }}>
        <Text theme={themes.kraftHeinz.text}>
          What kind of menu are you looking for this evening?
        </Text>
      </div>
      <Tiles>
        {tilesList.map((tile, i) => (
          <Tile
            key={i}
            tileTitleStyles={{
              fontSize: '1rem',
              fontWeight: 'bold',
            }}
            tileImageStyles={{
              backgroundSize: '50%',
            }}
            onClick={changePage}
            {...tile}
          />
        ))}
      </Tiles>
      <div
        style={{
          width: '100%',
          height: '30vh',
          background: `transparent url("${mrSausage}") center no-repeat`,
          backgroundSize: '20%',
        }}
      />
    </Layout.PageWrapper>
  );
};

export default MealStyle;
