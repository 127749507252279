import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { ToastContainer } from 'react-toastify';

import { Button, Form, Input } from '@/components';
import themes from '@/themes';
// import { getUrlParams } from '@/helpers';
import { useChangePageState } from '@/state';
// import { useApi } from '@/hooks';
// import { urls } from '@/urls';

type FormData = {
  username: string;
  email: string;
};

const schema = yup.object().shape({
  username: yup.string().required(),
  email: yup.string().email().required(),
});

const RegistrationForm = () => {
  // const { queryMap } = getUrlParams();
  // const { response, loading, makeRequest } = useApi({
  //   url: '',
  //   method: 'POST',
  // });
  const { register, handleSubmit, errors } = useForm<FormData>({
    resolver: yupResolver(schema),
  });
  const changePage = useChangePageState('final');

  // useEffect(() => {
  //   if (response) {
  //     changePage();
  //   }
  // }, [response, changePage]);

  const onSubmit = async (data: FormData) => {
    changePage();
    // await makeRequest({
    //   ...data,
    //   tagId: queryMap.tapWowTagId,
    //   deviceId: queryMap.deviceId,
    //   gameType: queryMap.gameType,
    // });
  };

  return (
    <>
      <ToastContainer />
      {/* <Spinner isShown={loading} /> */}
      <Form
        styles={{
          display: 'flex',
          width: '95%',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Input
          name="username"
          theme={themes.kraftHeinz.textInput}
          placeholder="Username"
          required={true}
          hasError={!!errors.username}
          errorMessage={errors.username ? errors.username.message : ''}
          register={register}
        />
        <div style={{ width: '100%', marginTop: '2rem' }}>
          <Input
            name="email"
            theme={themes.kraftHeinz.textInput}
            placeholder="Email"
            required={true}
            hasError={!!errors.email}
            errorMessage={errors.email ? errors.email.message : ''}
            register={register}
          />
        </div>
        <div
          style={{
            display: 'flex',
            width: '100%',
            margin: '4rem 0 0',
            justifyContent: 'flex-end',
          }}
        >
          <Button theme={themes.kraftHeinz.yellowButton} type="submit">
            Join
          </Button>
        </div>
      </Form>
    </>
  );
};

export default RegistrationForm;
