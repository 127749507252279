import React from 'react';

import { Image, Text, Layout, Button } from '@/components';
import themes from '@/themes';

import pageBg from '@/assets/images/final-page-bg.png';
import kraftHeinzLogo from '@/assets/images/kraft-heinz-logo-white.png';
import mrSausage from '@/assets/images/basic-mr-sausage.png';

const Final = () => {
  return (
    <Layout.PageWrapper
      styles={{
        background: `transparent url("${pageBg}") 50% no-repeat`,
        backgroundSize: 'cover',
      }}
    >
      <div style={{ margin: '3rem 0 1rem' }}>
        <Image src={kraftHeinzLogo} width="60%" />
      </div>
      <div style={{ width: '100%', marginBottom: '1rem' }}>
        <Text theme={themes.kraftHeinz.text}>Thanks for registering.</Text>
      </div>
      <Text theme={themes.kraftHeinz.text}>
        E-mail was sent to your address with the recipe along with video tips
        for successful preparation of this menu.
      </Text>
      <div style={{ margin: '2rem 0' }}>
        <Image src={mrSausage} width="50%" />
      </div>
      <div style={{ width: '100%', marginBottom: '2rem' }}>
        <Text
          fontFamily="Montserrat"
          weight="bold"
          size="1.2rem"
          color="#ffffff"
        >
          Bon Appetit!
        </Text>
      </div>
      <Button theme={themes.kraftHeinz.yellowButton}>More recipes</Button>
    </Layout.PageWrapper>
  );
};

export default Final;
