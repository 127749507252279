import styled from 'styled-components';

import checked from '@/assets/images/checked-star.png';
import unchecked from '@/assets/images/unchecked-star.png';

type TileImageProps = {
  image: string;
  styles?: {
    [props: string]: any;
  };
};

type TileTitleProps = {
  styles?: {
    [props: string]: any;
  };
};

type TileWrapperProps = {
  disabled: boolean;
  selectable: boolean;
  selected: boolean;
};

export const TileWrapper = styled.div<TileWrapperProps>`
  position: relative;
  display: flex;
  height: 45vw;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 25px;
  box-shadow: 0px 2px 4px 2px rgba(180, 180, 180, 0.3);
  /* pointer-events: none; */

  &:before {
    position: absolute;
    top: -22px;
    right: -10px;
    display: ${({ disabled, selectable }) =>
      disabled || !selectable ? 'none' : 'block'};
    width: 15vw;
    height: 15vw;
    background: transparent url("${({ selected }) =>
      selected ? checked : unchecked}") 50% no-repeat;
    background-size: cover;
    content: '';
  }

  &:after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: ${({ disabled }) => (disabled ? 'block' : 'none')};
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 25px;
    content: '';
  }
`;

export const TileImage = styled.div<TileImageProps>`
  display: flex;
  height: 75%;
  background: transparent url("${({ image }) => image}") 50% no-repeat;
  background-size: cover;
  ${({ styles }) => styles}
`;

export const TileTitle = styled.div<TileTitleProps>`
  display: flex;
  height: 25%;
  padding: 0 10px;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  font-family: 'Montserrat', monospace;
  font-size: 0.8rem;
  font-weight: 500;
  color: #183d6e;
  ${({ styles }) => styles}
`;
