import React, { useState } from 'react';

import { Image, Button, Tiles, Tile, Text, Layout } from '@/components';
import themes from '@/themes';
import { useChangePageState } from '@/state';

import pageHeader from '@/assets/images/page-header.png';
import kraftHeinzLogo from '@/assets/images/kraft-heinz-logo-white.png';
import sausagePack from '@/assets/images/sausage-pack.png';
import cheesePack from '@/assets/images/cheese-pack.png';
import baconPack from '@/assets/images/bacon-pack.png';
import totsPack from '@/assets/images/tots-pack.png';
import mrSausage from '@/assets/images/basic-mr-sausage.png';

const tilesList = [
  { title: 'Kraft Macaroni and Cheese', image: cheesePack },
  { title: 'Oscar Mayer Hot dogs', image: sausagePack },
  { title: 'Oscar Mayer Turkey Bacon', image: baconPack, disabled: true },
  { title: 'Ore Ida Tater Tots', image: totsPack, disabled: true },
];

const Meal = () => {
  const [selected, setSelected] = useState<number[]>([]);
  const changePage = useChangePageState('meal-style');

  const onSelect = (id: number) => () =>
    setSelected((prevList: number[]) => {
      if (prevList.includes(id)) {
        return prevList.filter((i) => i !== id);
      }

      return [...prevList, id];
    });

  return (
    <Layout.PageWrapper
      styles={{
        padding: 0,
        background: `transparent url("${mrSausage}") left 2rem bottom 1rem no-repeat`,
        backgroundSize: '20%',
      }}
    >
      <div
        style={{
          display: 'flex',
          padding: '0 1rem 2rem',
          marginBottom: '1rem',
          flexDirection: 'column',
          alignItems: 'center',
          background: `#ffffff url("${pageHeader}") left top no-repeat`,
          backgroundSize: 'cover',
        }}
      >
        <div style={{ margin: '5em 0 1rem' }}>
          <Image src={kraftHeinzLogo} width="60%" />
        </div>
        <Text theme={themes.kraftHeinz.text} styles={{ fontSize: '4rem' }}>
          Let’s figure out what’s for the dinner tonight with the help of
          KraftHeinz. Make a few selections from the choices below and we will
          help you create a great menu.
        </Text>
      </div>
      <Tiles>
        {tilesList.map((tile, i) => (
          <Tile
            key={i}
            selectable={true}
            selected={selected.includes(i)}
            onClick={onSelect(i)}
            {...tile}
          />
        ))}
      </Tiles>
      <div
        style={{
          margin: '3rem 1rem 4rem',
          alignSelf: 'flex-end',
        }}
      >
        <Button
          disabled={!selected.length}
          theme={themes.kraftHeinz.yellowButtonMealSelection}
          onClick={changePage}
        >
          Go to Meal Style
        </Button>
      </div>
    </Layout.PageWrapper>
  );
};

export default Meal;
