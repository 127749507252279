import { css } from 'styled-components';

import btnBg from '@/assets/images/btn-bg-yellow.png';
import dinnerDetectiveBtnBg from '@/assets/images/dinner-detective-btn-bg.png';

const commonYellowButtonCss = css`
  min-width: 250px;
  height: 80px;
  padding: 0 5vw;
  background: transparent url("${btnBg}") 50% no-repeat;
  background-position: cover;
  border: 2px solid #fff;
  border-radius: 50px;

  & span {
    font-family: 'Montserrat', monospace;
    font-weight: bold;
    font-size: 7vw;
    color: #183D6E;
  }
`;

export default {
  kraftHeinz: {
    yellowButton: css`
      ${commonYellowButtonCss}
    `,
    yellowButtonMealSelection: css`
      ${commonYellowButtonCss}

      & span {
        font-size: 6vw;
      }
    `,
    yellowButtonIngredientsSelection: css`
      ${commonYellowButtonCss}

      & span {
        font-size: 5vw;
      }
    `,
    dinnerDetectiveButton: css`
      ${commonYellowButtonCss}
      width: 90vw;
      height: 10vh;
      background: transparent url("${dinnerDetectiveBtnBg}") 50% no-repeat;
      background-size: contain;
    `,
    text: css`
      width: 95%;
      font-family: 'Montserrat', monospace;
      font-size: 4vw;
      font-weight: 500;
      color: #ffffff;
      line-height: 1.5rem;
    `,
    textInput: css`
      &:before {
        color: #ffffff;
      }

      & input {
        padding: 3vw 4vw;
        font-size: 4.3vw;
        font-family: 'Montserrat', monospace;
        color: #ffffff;
        border-bottom: 2px solid rgba(255, 255, 255, 0.6);

        &:focus {
          border-bottom: 2px solid #6eb42e;
        }

        &::placeholder {
          color: rgba(255, 255, 255, 0.6);
        }
      }
    `,
  },
};
