import React from 'react';

import { Image, Text, Layout, SpinWheel } from '@/components';

import kraftHeinzLogo from '@/assets/images/kraft-heinz-logo-white.png';
import pageBg from '@/assets/images/spin-page-bg.png';
import mrSausage from '@/assets/images/basic-mr-sausage.png';

const SpinTheWheel = () => (
  <Layout.PageWrapper
    styles={{
      background: `url("${mrSausage}") left 2rem bottom 2rem no-repeat, url("${pageBg}") top center no-repeat`,
      backgroundSize: '20%, cover',
      paddingLeft: 0,
      paddingRight: 0,
    }}
  >
    <Layout.ContentWrapper>
      <div style={{ marginTop: '4rem' }}>
        <Image src={kraftHeinzLogo} width="50%" />
      </div>
      <div style={{ margin: '1rem 0 0.5rem' }}>
        <Text
          fontFamily="Montserrat"
          weight="600"
          size="1.2rem"
          styles={{ lineHeight: '2rem' }}
        >
          15 Minute Meal!
        </Text>
      </div>
      <div style={{ width: '70%', marginBottom: '1rem' }}>
        <Text
          fontFamily="Montserrat"
          weight="600"
          size="0.9rem"
          styles={{ lineHeight: '1.5rem' }}
        >
          Let’s spin the wheel below to see….what’s for dinner?
        </Text>
      </div>
      <SpinWheel
        numberOfSegments={8}
        pointerAngle={23}
        segments={[
          { text: 'funny' },
          { text: 'fancy' },
          { text: 'fiesta' },
          { text: 'gametime' },
          { text: 'funny' },
          { text: 'fancy' },
          { text: 'fiesta' },
          { text: 'gametime' },
        ]}
      />
    </Layout.ContentWrapper>
  </Layout.PageWrapper>
);

export default SpinTheWheel;
