import React from 'react';
import { hydrate, render } from 'react-dom';
import { RecoilRoot } from 'recoil';
// import axios from 'axios';

import App from './App';
// import { urls } from '@/urls';
// import { getUrlParams } from '@/helpers';

import 'react-toastify/dist/ReactToastify.css';
import '@/assets/index.css';

const init = async () => {
  try {
    // const { queryMap } = getUrlParams();
    // const [{ data: facts }, { data: participant }] = await Promise.all([
    //   await axios.get(urls.GET_FACTS),
    //   await axios.get(urls.GET_PARTICIPANT(queryMap.deviceId)),
    // ]);

    const rootElement = document.getElementById('root');

    if (rootElement && rootElement.hasChildNodes()) {
      hydrate(
        <RecoilRoot>
          <App />
        </RecoilRoot>,
        rootElement
      );
    } else {
      render(
        <RecoilRoot>
          <App />
        </RecoilRoot>,
        rootElement
      );
    }
  } catch (e) {
    console.log('Initialisation error =>', e);
  }
};

init();
