import React, { useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import {
  Home,
  SpinTheWheel,
  Meal,
  MealStyle,
  Win,
  Ingredients,
  FindIngredients,
  Registration,
  Final,
} from '@/pages';
import { pageState } from '@/state';

const pagesMap: { [prop: string]: any } = {
  home: Home,
  meal: Meal,
  win: Win,
  'meal-style': MealStyle,
  'spin-the-wheel': SpinTheWheel,
  ingredients: Ingredients,
  'find-ingredients': FindIngredients,
  registration: Registration,
  final: Final,
};

const App = () => {
  const page = useRecoilValue(pageState);

  useEffect(() => {
    const preloader = document.getElementById('preloader');
    preloader?.remove();
  }, []);

  const Page = pagesMap[page];

  return <Page />;
};

export default App;
