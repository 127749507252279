import React, { useEffect, useState } from 'react';

import { Image as AppImage, Button } from '@/components';
import { SpinWheelWrapper } from './styled';

import themes from '@/themes';
import { useChangePageState } from '@/state';
import SpinWheelGame from './lib';

import pointer from '@/assets/images/spin-arrow.png';
import spinWheelImg from '@/assets/images/spin-wheel.png';

type SpinWheelProps = {
  numberOfSegments: number;
  pointerAngle?: number;
  segments: { text: string }[];
};

const SpinWheel = ({
  numberOfSegments,
  pointerAngle,
  segments,
}: SpinWheelProps) => {
  const [width] = useState(window.innerWidth);
  const [game, setGame] = useState<null | SpinWheelGame>(null);
  // const [winSegment, setWinSegment] = useState('');
  const [gameStarted, setGameStarted] = useState(false);
  const changePage = useChangePageState('win');

  useEffect(() => {
    const g = new SpinWheelGame({
      canvasId: 'spin-wheel-canvas',
      numberOfSegments,
      pointerAngle,
      segments,
      onFinish: () => setTimeout(() => changePage(), 1000),
    });

    g.initBackgroundImage(spinWheelImg, width);

    setGame(g);
  }, [width]);

  // useEffect(() => {
  //   if (winSegment) {
  //     setTimeout(() => changePage(), 500);
  //   }
  // }, [winSegment, changePage]);

  const spin = () => {
    if (game) {
      setGameStarted(true);
      game.run();
    }
  };

  return (
    <SpinWheelWrapper>
      <AppImage
        src={pointer}
        width="60vw"
        height="60vw"
        styles={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      />
      <canvas id="spin-wheel-canvas" width={width} height={width}>
        Canvas not supported, please use another browser.
      </canvas>
      <div
        style={{
          margin: '3rem 1rem',
          alignSelf: 'flex-end',
        }}
      >
        <Button
          theme={themes.kraftHeinz.yellowButtonMealSelection}
          disabled={gameStarted}
          onClick={spin}
        >
          SPIN!
        </Button>
      </div>
    </SpinWheelWrapper>
  );
};

export default SpinWheel;
