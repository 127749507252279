import React from 'react';

import { Image, Text, Layout, RegistrationForm } from '@/components';
import themes from '@/themes';

import pageBg from '@/assets/images/registration-page-bg.png';
import kraftHeinzLogo from '@/assets/images/kraft-heinz-logo-white.png';
import mrSausage from '@/assets/images/basic-mr-sausage.png';

const Registration = () => {
  return (
    <Layout.PageWrapper
      styles={{
        background: `url("${mrSausage}") left 1rem bottom 4rem no-repeat, url("${pageBg}") 50% no-repeat`,
        backgroundSize: '20%, cover',
      }}
    >
      <div style={{ margin: '4rem 0 1rem' }}>
        <Image src={kraftHeinzLogo} width="60%" />
      </div>
      <Text theme={themes.kraftHeinz.text}>
        Would you like to add this recipe to a personalized recipe box or share
        it to social media. Provide the information below and we will get you
        registered for the KraftHeinz Club. Members of the club have access to
        special offers and promotions!
      </Text>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          width: '90%',
          margin: '2rem 0 4rem',
        }}
      >
        <RegistrationForm />
      </div>
    </Layout.PageWrapper>
  );
};

export default Registration;
