import React from 'react';

import { Image, Button, Tiles, Tile, Text, Layout } from '@/components';
import themes from '@/themes';
import { useChangePageState } from '@/state';

import pageHeader from '@/assets/images/page-header.png';
import kraftHeinzLogo from '@/assets/images/kraft-heinz-logo-white.png';
import sausagePack from '@/assets/images/sausage-pack.png';
import cheesePack from '@/assets/images/cheese-pack.png';
import spinach from '@/assets/images/spinach.png';
import zesty from '@/assets/images/zesty.png';
import jello from '@/assets/images/jello.png';
import coolWhip from '@/assets/images/cool-whip.png';
import headerImage from '@/assets/images/dinner-detective-header-image.png';
import footerImage from '@/assets/images/dinner-detective-page-footer.png';

const tilesList = [
  { title: 'Kraft Macaroni and Cheese', image: cheesePack },
  { title: 'Oscar Mayer Hot dogs', image: sausagePack },
  { title: 'Spinach', image: spinach },
  { title: 'Good Seasons Zesty Italian', image: zesty },
  { title: 'Orange Jello', image: jello },
  { title: 'Cool Whip', image: coolWhip },
];

const Ingredients = () => {
  const changePage = useChangePageState('find-ingredients');

  return (
    <Layout.PageWrapper
      styles={{
        padding: '0 0 8rem',
        background: `transparent url("${footerImage}") left bottom no-repeat`,
        backgroundSize: 'contain',
      }}
    >
      <div
        style={{
          display: 'flex',
          padding: '0 1rem 2rem',
          flexDirection: 'column',
          alignItems: 'center',
          background: `#ffffff url("${pageHeader}") left top no-repeat`,
          backgroundSize: '100vw 30vh',
        }}
      >
        <div style={{ margin: '4rem 0 1rem' }}>
          <Image src={kraftHeinzLogo} width="60%" />
        </div>
        <Text theme={themes.kraftHeinz.text}>
          Hot Dog from the Sea With “Seaweed” Salad and “Clownfish” Jello.
        </Text>
      </div>
      <Image src={headerImage} width="100%" />
      <div style={{ width: '80%', margin: '1rem 0 1.5rem' }}>
        <Text
          fontFamily="Montserrat"
          size="0.9rem"
          color="#183D6E"
          styles={{ lineHeight: '1.5rem' }}
        >
          A fun, healthy meal featuring a creative presentation of Oscar Mayer
          Hot Dogs.
        </Text>
      </div>
      <Button
        theme={themes.kraftHeinz.dinnerDetectiveButton}
        onClick={changePage}
      />
      <div style={{ width: '80%', margin: '1.5rem 0 0.5rem' }}>
        <Text
          fontFamily="Montserrat"
          weight="bold"
          size="1.2rem"
          color="#183D6E"
        >
          Ingredients
        </Text>
      </div>
      <Tiles>
        {tilesList.map((tile, i) => (
          <Tile key={i} {...tile} />
        ))}
      </Tiles>
    </Layout.PageWrapper>
  );
};

export default Ingredients;
