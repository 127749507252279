import React from 'react';

import { IButtonContainerProps } from './types';
import { ButtonContainer } from './styled';

interface IButtonProps extends IButtonContainerProps {
  onClick?: (e: React.SyntheticEvent) => void;
  type?: 'button' | 'submit' | 'reset' | undefined;
  children?: React.ReactNode;
}

const Button = ({
  styles = {},
  theme = [],
  type = 'button',
  disabled = false,
  onClick,
  children,
}: IButtonProps) => (
  <ButtonContainer
    styles={styles}
    theme={theme}
    type={type}
    disabled={disabled}
    onClick={onClick}
  >
    <span>{children}</span>
  </ButtonContainer>
);

export default Button;
