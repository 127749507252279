import React from 'react';

import { Image, Button, Layout } from '@/components';
import themes from '@/themes';
import { useChangePageState } from '@/state';

import pageBg from '@/assets/images/home-page-bg.png';
import kraftHeinzLogo from '@/assets/images/kraft-heinz-logo-white.png';
import whatsForDinnerTitle from '@/assets/images/whats-for-dinner-title.png';
import mrSausage from '@/assets/images/basic-mr-sausage.png';
import poweredBy from '@/assets/images/powered-by.png';

const Home = () => {
  const changePage = useChangePageState('meal');

  return (
    <Layout.PageWrapper
      styles={{
        justifyContent: 'space-between',
        background: `transparent url("${pageBg}") 50% no-repeat`,
        backgroundSize: 'cover',
      }}
    >
      <Layout.PageWrapper>
        <div style={{ marginTop: '3rem' }}>
          <Image src={kraftHeinzLogo} width="60%" />
        </div>
        <div style={{ marginTop: '2rem' }}>
          <Image src={whatsForDinnerTitle} width="60%" />
        </div>
        <div style={{ marginTop: '1rem' }}>
          <Image src={mrSausage} width="40%" />
        </div>
        <div style={{ margin: '2rem 0' }}>
          <Button
            styles={{ textTransform: 'uppercase' }}
            theme={themes.kraftHeinz.yellowButton}
            onClick={changePage}
          >
            Start
          </Button>
        </div>
      </Layout.PageWrapper>
      <div style={{ marginBottom: '2rem' }}>
        <Image src={poweredBy} width="50%" />
      </div>
    </Layout.PageWrapper>
  );
};

export default Home;
