import React from 'react';

import { TilesWrapper } from './styled';

type TilesProps = {
  children: React.ReactChild[];
};

const Tiles = ({ children }: TilesProps) => (
  <TilesWrapper>{children}</TilesWrapper>
);

export default Tiles;
